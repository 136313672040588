import React, { useEffect, useState } from 'react';
import Table from '../components/Table/Table';
import { Link } from 'react-router-dom';


const Dashboard = () => {
    const [data, setData] = useState();

   /* const dataLTable = [
        { id: 1, name: 'Item 1', description: 'This is item 1' },
        { id: 2, name: 'Item 2', description: 'This is item 2' },
        { id: 3, name: 'Item 3', description: 'This is item 3' },
        { id: 4, name: 'Item 4', description: 'This is item 4' },
        { id: 5, name: 'Item 5', description: 'This is item 5' },
        { id: 6, name: 'Item 6', description: 'This is item 6' },
        { id: 7, name: 'Item 7', description: 'This is item 7' },
        { id: 8, name: 'Item 8', description: 'This is item 8' },
        { id: 9, name: 'Item 9', description: 'This is item 9' },
        { id: 10, name: 'Item 10', description: 'This is item 10' },
      ];*/

    useEffect(() => {
        fetch(`api/reports/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            credentials: "include",
          })
            .then((response) => response.json())
            .then((response) => {
            console.log(response)
             setData(response)
            })
            .catch((error) => {
              console.error("There was an error fetching the reports!", error);
            });
          
    }, []);

    return (
        <div className="container">
            <h1>Jegyzőkönyvek</h1>
            <Link to="/report"><button>Új jegyzőkönyv</button></Link>
            {data && <Table data={data} itemsPerPage={15} headerLabels={["Azonosító", "Jármű","Státusz"]}></Table>}
          
        </div>
    );
};

export default Dashboard;