import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const url = new URL(window.location.href);
  let reportId = url.searchParams.get("reportId") ? url.searchParams.get("reportId") : 0;

  const [data, setData] = useState([]);
  const [categoryId, setCategoryId] = useState(1);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  function getCategoryFieldsByReport(categoryId,categoriesLoc) {
    fetch(`api/report_fields_by_report/${reportId}/${categoryId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let categoryData = {};
        let fields = [];

        response.fields.map((field) => {
          fields.push({ label: field.label,id:field.field_id,remark: field.remarks });
        });
        //categoryData.push({"category" : response.category, "fields" : fields})
        categoryData.category = response.category;
        categoryData.fields = fields;
        // console.log(categoryData)
        setData((oldData) => [...oldData, categoryData]);
        const index = categoriesLoc.findIndex(obj => obj.id === response.categoryId);
        console.log(index)
        setCurrentCategoryIndex(index);
        getCategoryFieldsByReport(categoriesLoc[index+1].id,categoriesLoc);
      })
      .catch((error) => {
        if (currentCategoryIndex == 0) {
          //
        }
      });
  }

  function getEmptyCategoryFields(categoryId) {
    fetch(`api/fields_by_category/${categoryId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let categoryData = {};
        let fields = [];

        response.fields.map((field) => {
          fields.push({ label: field.label , id:field.id,remark:"" });

          saveReportField(reportId,field.id,false,"")
        });
        //categoryData.push({"category" : response.category, "fields" : fields})
        categoryData.category = response.category;
        categoryData.fields = fields;
        // console.log(categoryData)
        setData((oldData) => [...oldData, categoryData]);
        //console.log(data);


      });

   
  }

  useEffect(() => {
    //getEmptyCategoryFields(1)
    // getEmptyCategoryFields(2)
    // getEmptyCategoryFields(3)
    let categoriesLoc=[]
    fetch(`api/vehicles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);

        setVehicles(response);
      })
      .catch((error) => {
        console.error("There was an error fetching the vehicles!", error);
      });

    fetch(`api/categories/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
       // console.log(response);

        setCategories(response);
        categoriesLoc=response
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
      });

    if (reportId) {
      fetch(`api/report/${reportId}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        credentials: "include",
      })
        .then((response) => response.json())
        .then((response) => {
          setSelectedVehicleId(response.vehicle);
          getCategoryFieldsByReport(categoriesLoc[currentCategoryIndex].id,categoriesLoc)
        })
        .catch((error) => {
          console.error("There was an error fetching the report!", error);
        });
    }
  }, []);

  const handleChange = (event) => {
    const vehicleId = event.target.value;
    setSelectedVehicleId(vehicleId);
    //saveReport();
    // onSelectVehicle(vehicleId);  // Pass the selected vehicle ID to the parent component
  };

  useEffect(() => {
    if (selectedVehicleId && reportId==0) 
        saveReport();
  }, [selectedVehicleId]);

  function saveReport() {
    fetch("api/save_report/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        vehicle_id: parseInt(selectedVehicleId),
        user_id: 1, //TODO change user id from context
        is_closed: false,
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        const params = new URLSearchParams(window.location.search);
        params.set("reportId", data.report_id);
        navigate(`?${params.toString()}`, { replace: true });
        reportId=data.report_id
        getEmptyCategoryFields(categories[currentCategoryIndex].id);
       // setCurrentCategoryIndex(currentCategoryIndex+1)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function changeRemark(event) {
    const el = event.target;
    const fieldId = el.dataset.fieldid;
    const newValue = el.value;
    const index = data.findIndex(item => item.fields.find(f => f.id == fieldId));
    if (index > -1) {
      const newFields = data[index].fields.map(f => {
        if (f.id == fieldId) {
          return {...f, remark: newValue}
        }
        return f;
      });
      setData(
        data.map((item, i) => {
          if (i == index) {
            return {...item, fields: newFields}
          }
          return item;
        })
      );
    }
    console.log(event.target.value, event.target.dataset.fieldid);
    saveReportField(reportId,event.target.dataset.fieldid,false,event.target.value)
  }

  function saveReportField(reportId,fieldId,checkbox, remark,) {
    fetch("api/save_report_field/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        report_id: reportId,
        field_id: fieldId,
        is_checked: true,
        remarks: remark,
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function nextCategory(){
    console.log(currentCategoryIndex)
    setCurrentCategoryIndex(currentCategoryIndex+1)
    getEmptyCategoryFields(categories[currentCategoryIndex+1].id)

    //TODO field validation
  }

  return (
    <>
      <div class="container" id="container">
        <div className="table">
          <div className="header row">Jegyzőkönyv</div>

          <select id="vehicle-select" value={selectedVehicleId} onChange={handleChange}>
            <option value="" disabled>
              Válassz egy járművet
            </option>
            {vehicles &&
              vehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.display_name}
                </option>
              ))}
          </select>
          {data.map((category) => (
            <>
              <div className="category row">{category.category}</div>
              {category.fields.map((field) => (
                <div className="row">
                  <div className="label">{field.label}</div>
                  <div className="checkbox">
                    <input type="checkbox" />
                  </div>
                  <div className="remarks">
                    <input data-fieldid={field.id} value={field.remark} id={field.id} onChange={changeRemark} type="text" />
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>
        <div className="button-container">
          <button className="button" id="nextButton" onClick={nextCategory}>
            Tovább
          </button>
        </div>
      </div>
    </>
  );
};

export default Report;
