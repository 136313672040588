import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    fetch("api/user/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.first_name) {
          setUser(response);
        } else {
          localStorage.removeItem("token");
          window.location.href = "/";
        }
      });
  }, []);

  function logOut() {
    localStorage.removeItem("token");
    window.location.href = "/";
  }
  return (
    <>
      {user ? (
        <div className="header-container">
          <span>Üdv, {user.first_name}</span>
          <button onClick={logOut}> Kijelentkezés</button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
