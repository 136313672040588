import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";

const Table = ({ data, itemsPerPage,headerLabels }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  // Headers derived from object keys
  const headers = useMemo(() => Object.keys(data[0]), [data]);

  // Filtered items based on search query
  const filteredItems = useMemo(() => {
    return data.filter((item) => headers.some((header) => item[header].toString().toLowerCase().includes(searchQuery.toLowerCase())));
  }, [data, headers, searchQuery]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  // Get current items for the page
  const currentItems = useMemo(() => {
    return filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [filteredItems, currentPage, itemsPerPage]);

  // Handle page click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  return (
    <div>
      <div>
        <input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
      </div>
      <div className="table">
        <div className="header row">
          {headers.map((header, index) => (
            <div key={index} className="header-item ">
              {headerLabels[index]}
            </div>
          ))}
          <div  className="header-item ">
              Műveletek
            </div>
        </div>

        <div className="item-list">
          {currentItems.map((item, index) => (
            <div key={index} className="item row">
              {headers.map((header) => (
                <div key={header} className="item-field">
                  {item[header]}
                </div>
              ))}
               <div  className="item-field">
                 <Link to={"/report?reportId="+item.id}><button>Módosít</button></Link>
                </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        <button onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1}>
          {"<<"}
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button key={index} onClick={() => handlePageClick(index + 1)} className={index + 1 === currentPage ? "active" : ""}>
            {index + 1}
          </button>
        ))}

        <button onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages}>
          >>
        </button>
      </div>
    </div>
  );
};

export default Table;
